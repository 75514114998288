.row {
  display: grid;
  grid-template-columns: 40% 10% 20% 10% 10% 10%;
}

.edit {
  font-size: 0.5em;
  padding: 0.5em 1em;
  margin: 0.25em 0.5em;
  color: #8c9299;
  border: #8c9299a4 solid 1px;
  border-radius: 5px;
  background-color: transparent;
}
