.techRow {
  margin-top: 14px;
  display: grid;
  width: 100%;
  grid-template-columns: 56px 150px auto 15px;
  align-items: center;
  /* justify-content: space-between; */
}

.nameDot {
  height: 32px;
  width: 32px;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: red;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid white;
  margin-right: -7px;
}

.statusContainer {
  display: flex;
  align-items: center;
}

.statusContainer > select {
  color: #1c2735;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  outline: none;
}
