.backout {
  margin-bottom: 12px;
  display: flex;
}

.backout > * {
  color: #8c9299;
  font-size: 18px;
  margin-right: 5px;
}
