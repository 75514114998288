input.inputDate {
  background-color: #f0f1f2;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 16px;
}

.toolsRow select {
  /* min-width: 20em;
  max-width: 25em; */
  width: 15em;
}
